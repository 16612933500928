import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FormattedMessage } from 'react-intl';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      return setError('register.passwordMismatch');
    }

    try {
      setError('');
      setLoading(true);
      console.log('Attempting registration with:', { email });
      await register(email, password);
      navigate('/login');
    } catch (err) {
      console.error('Registration error:', err);
      setError('register.error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">
        <FormattedMessage id="register.title" defaultMessage="Register" />
      </h2>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <FormattedMessage id={error} defaultMessage="An error occurred during registration." />
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <FormattedMessage id="register.emailLabel" defaultMessage="Email Address" />
          </label>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="email@example.com"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <FormattedMessage id="register.passwordLabel" defaultMessage="Password" />
          </label>
          <input
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="••••••••"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <FormattedMessage 
              id="register.confirmPasswordLabel" 
              defaultMessage="Confirm Password" 
            />
          </label>
          <input
            type="password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="••••••••"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600 disabled:bg-green-300"
        >
          <FormattedMessage 
            id="register.submitButton" 
            defaultMessage={loading ? "Creating Account..." : "Register"} 
          />
        </button>
      </form>
    </div>
  );
}

export default RegisterPage;