// src/hooks/useDocumentViewEffects.js
import { useEffect, useCallback } from 'react';
import { documentService } from '../services/documentService';

export function useDocumentViewEffects({
  documentId,
  token,
  location,
  isEditMode,
  submissionId,
  setFormSchema,
  setFormData,
  setLoading,
  setError,
  setSubmitting,
  setActiveStep,
  getCurrentFormData,
  formSchema,
  navigate,
  formRef,
}) {
  const handleGeneratePDF = useCallback(async (saveData) => {
    try {
      setSubmitting(true);
      setError(null);

      // Get the CURRENT form data including any unsaved changes
      let currentFormData = getCurrentFormData();
      console.log('Current form data before save:', currentFormData);

      if (saveData) {
        if (submissionId) {
          console.log('Updating submission with data:', currentFormData);
          const updateResponse = await documentService.updateUserForm(token, submissionId, {
            data: currentFormData,
          });
          console.log('Update response:', updateResponse);

          // Verify the update
          const updatedSubmission = await documentService.getUserSubmission(token, submissionId);
          console.log('Verification data after update:', updatedSubmission);

          if (updatedSubmission?.data) {
            currentFormData = updatedSubmission.data;
          }
        } else {
          await documentService.submitForm(token, documentId, {
            data: currentFormData,
            form_id: documentId,
            form_schema: formSchema,
          });
        }
      }

      console.log('Generating PDF with data:', currentFormData);
      const pdfBlob = await documentService.generatePDF(token, documentId, currentFormData);
      documentService.downloadPDF(pdfBlob, documentId, !saveData);

      if (saveData) {
        navigate('/view');
      }
    } catch (err) {
      setError(err.message || 'Failed to generate PDF');
      console.error('Error handling PDF generation:', err);
    } finally {
      setSubmitting(false);
    }
  }, [token, documentId, getCurrentFormData, formSchema, navigate, submissionId, setSubmitting, setError]);

  // Effect for fetching form data
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setLoading(true);
        setError(null);
    
        console.log('Starting fetch with:', { isEditMode, submissionId, documentId });
    
        // First get the form schema
        const forms = await documentService.getFormsUser(token);
        const selectedForm = forms.find((form) => form._id === documentId);
    
        if (!selectedForm) {
          throw new Error('Form not found');
        }
    
        console.log('Selected form:', selectedForm);
        setFormSchema(selectedForm.form_schema);
    
        // Handle form data loading
        if (isEditMode && submissionId) {
          try {
            console.log('Fetching submission:', submissionId);
            const submission = await documentService.getUserSubmission(token, submissionId);
            console.log('Received submission:', submission);
    
            if (submission && submission.data) {
              // Extract just the form data - either from data.data or directly from steps
              const formData = submission.data.data || submission.data;
              console.log('Extracted form data:', formData);
              
              // Clean up the data structure to only include step data
              const cleanData = Object.keys(formData)
                .filter(key => key.startsWith('step'))
                .reduce((acc, key) => {
                  acc[key] = formData[key];
                  return acc;
                }, {});
                
              console.log('Setting clean form data:', cleanData);
              setFormData(cleanData);
            } else {
              console.warn('Invalid submission data structure:', submission);
              throw new Error('No data found in submission');
            }
          } catch (submissionError) {
            console.error('Error fetching submission:', submissionError);
            throw new Error('Failed to load submission data');
          }
        } else {
          // For new forms, check sessionStorage or start empty
          const storedData = sessionStorage.getItem('editFormData');
          if (storedData) {
            try {
              const parsedData = JSON.parse(storedData);
              console.log('Setting form data from session storage:', parsedData);
              setFormData(parsedData);
              sessionStorage.removeItem('editFormData');
            } catch (parseError) {
              console.error('Error parsing stored form data:', parseError);
              setFormData({});
            }
          } else {
            setFormData({});
          }
        }
      } catch (err) {
        const errorMessage = err.response?.data?.detail || err.message || 'Failed to load form';
        setError(errorMessage);
        console.error('Error loading form:', err);
      } finally {
        setLoading(false);
      }
    };

    if (documentId && token) {
      fetchFormData();
    }
  }, [documentId, token, location.search, isEditMode, submissionId, setFormSchema, setFormData, setLoading, setError]);

  // Effect for handling form reset
  useEffect(() => {
    const handleReset = () => {
      setFormData({}); // Reset form data to empty
      setActiveStep(0); // Reset to first step
    };

    window.addEventListener('resetForm', handleReset);
    return () => {
      window.removeEventListener('resetForm', handleReset);
    };
  }, [setFormData, setActiveStep]);

  // Effect for handling PDF generation
  useEffect(() => {
    const handleGeneratePDFEvent = async (event) => {
      const { saveData } = event.detail;
      await handleGeneratePDF(saveData);
    };

    window.addEventListener('generatePDF', handleGeneratePDFEvent);
    return () => {
      window.removeEventListener('generatePDF', handleGeneratePDFEvent);
    };
  }, [handleGeneratePDF]);

  return { handleGeneratePDF };
}