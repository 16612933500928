// src/services/documentService.js
import axios from 'axios';
import { getApiUrl } from '../config/config';

export const documentService = {
  // Get all forms for user
  async getFormsUser(token) {
    try {
      const response = await axios.get(getApiUrl('/user/forms'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching forms:', error);
      throw error;
    }
  },

  // Get all forms for admin
  async getForms(token) {
    try {
      const response = await axios.get(getApiUrl('/forms'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching forms:', error);
      throw error;
    }
  },

  // Get a specific user's form submission by ID
  async getUserSubmission(token, submissionId) {
    try {
      const response = await axios.get(
        getApiUrl(`/user/completed_forms/${submissionId}`),
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching submission:', error);
      throw error;
    }
  },

  async getFormSubmissions(token, formId) {
    try {
      const response = await axios.get(getApiUrl('/user/completed_forms'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      // Filter submissions for the specific form
      return response.data.filter(submission => submission.form_id === formId);
    } catch (error) {
      console.error('Error fetching form submissions:', error);
      throw error;
    }
  },

  // Submit a form
  async submitForm(token, formId, formData) {
    try {
      const response = await axios.post(
        getApiUrl(`/user/forms/${formId}/submit`),
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  },

  // Add a new form
  async addForm(token, formData) {
    try {
      const response = await axios.post(
        getApiUrl('/forms/'),
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding form:', error);
      throw error;
    }
  },

  // Get users with access to a form
  async getFormUsers(token, formId) {
    try {
      const response = await axios.get(
        getApiUrl(`/forms/${formId}/users`),
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data.users;
    } catch (error) {
      console.error('Error fetching form users:', error);
      throw error;
    }
  },

  // Get all users
  async getAllUsers(token) {
    try {
      const response = await axios.get(
        getApiUrl('/users'),
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },

  // Grant access to a form
  async grantAccess(token, formId, userId) {
    try {
      const response = await axios.post(
        getApiUrl(`/forms/${formId}/allocate`),
        { user_id: userId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error granting access:', error);
      throw error;
    }
  },

  // Remove access from a form
  async removeAccess(token, formId, userId) {
    try {
      const response = await axios.post(
        getApiUrl(`/forms/${formId}/remove_access`),
        { user_id: userId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error removing access:', error);
      throw error;
    }
  },

  async generatePDF(token, formId, formData) {
    try {
      const response = await axios.post(
        getApiUrl('/user/generate-pdf/'),
        {
          form_id: formId,
          user_id: token,
          form_data: formData
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob' // Important for handling the PDF blob response
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  },

  // Helper method to trigger PDF download
  downloadPDF(pdfBlob, documentId, isPreview = false) {
    const pdfUrl = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `document_${documentId}${isPreview ? '_preview' : ''}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(pdfUrl);
  },

  async updateUserForm(token, submissionId, formData) {
    try {
      console.log('Updating form submission with data:', formData);
      // Clone the data to make sure we're not modifying the original
      const dataToSend = JSON.parse(JSON.stringify(formData));
      
      const response = await axios.put(
        getApiUrl(`/user/completed_forms/${submissionId}`),
        dataToSend,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Update response:', response.data);
      
      // Immediately fetch the updated data to verify
      const verificationResponse = await this.getUserSubmission(token, submissionId);
      console.log('Verification data:', verificationResponse);
      
      return response.data;
    } catch (error) {
      console.error('Error updating form submission:', error.response?.data || error);
      throw error;
    }
  },

  // Delete a form
  async deleteForm(token, formId) {
    try {
      const response = await axios.delete(
        getApiUrl(`/forms/${formId}`),
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting form:', error);
      throw error;
    }
  }
};