import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useClickOutside } from '../utils/useClickOutside';

export default function DocumentDropdown({ documents, isOpen, onClose, onSelect }) {
  const dropdownRef = useRef(null);

  // Use custom hook to close dropdown when clicking outside
  useClickOutside(dropdownRef, onClose);

  if (!isOpen) return null;

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg overflow-hidden z-50" // Changed z-20 to z-50
      style={{ position: 'fixed' }} // Added fixed positioning
      id="document-dropdown"
    >
      <div className="py-2">
        {/* Handle empty state */}
        {documents.length === 0 ? (
          <div className="px-4 py-2 text-sm text-gray-700">
            <FormattedMessage id="nav.noDocuments" defaultMessage="No documents available" />
          </div>
        ) : (
          documents.map((doc) => (
            <button
              key={doc._id}
              onClick={() => {
                onSelect(doc);
                onClose(); // Close the dropdown after selecting a document
              }}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              {doc.title || (
                <FormattedMessage id="nav.untitledDocument" defaultMessage="Untitled Document" />
              )}
            </button>
          ))
        )}
      </div>
    </div>
  );
}
