// src/components/ui/alert.js
import React from 'react';

export const Alert = ({ children, variant = "default", className = "" }) => {
  const baseStyles = "p-4 rounded-lg mb-4";
  const variants = {
    default: "bg-blue-100 text-blue-700 border border-blue-200",
    destructive: "bg-red-100 text-red-700 border border-red-200",
    success: "bg-green-100 text-green-700 border border-green-200",
  };

  return (
    <div className={`${baseStyles} ${variants[variant]} ${className}`}>
      {children}
    </div>
  );
};

export const AlertTitle = ({ children, className = "" }) => {
  return (
    <h5 className={`font-medium mb-1 ${className}`}>
      {children}
    </h5>
  );
};

export const AlertDescription = ({ children, className = "" }) => {
  return (
    <div className={`text-sm ${className}`}>
      {children}
    </div>
  );
};