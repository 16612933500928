// src/pages/DocumentView.js
import React, { useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Alert, AlertTitle } from '../components/ui/alert';
import { FormattedMessage } from 'react-intl';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useDocumentViewEffects } from '../hooks/useDocumentViewEffects';
import { documentService } from '../services/documentService';

function DocumentView() {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useAuth();
  const [formSchema, setFormSchema] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const formRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const submissionId = searchParams.get('submissionId');
  const isEditMode = searchParams.get('mode') === 'edit';

  const getCurrentStep = useCallback(() => {
    if (!formSchema) return null;
    const steps = Object.keys(formSchema.properties || {});
    return steps[activeStep];
  }, [formSchema, activeStep]);

  const getCurrentFormData = useCallback(() => {
    const allFormData = { ...formData };
    if (formRef.current) {
      const currentStep = getCurrentStep();
      const currentFormState = formRef.current.state?.formData;
      if (currentStep && currentFormState) {
        allFormData[currentStep] = currentFormState[currentStep];
      }
    }
    console.log('Current complete form data:', allFormData);
    return allFormData;
  }, [formData, getCurrentStep]);

  useDocumentViewEffects({
    documentId,
    token,
    location,
    isEditMode,
    submissionId,
    setFormSchema,
    setFormData,
    setLoading,
    setError,
    setSubmitting,
    setActiveStep,
    getCurrentFormData,
    formSchema,
    navigate,
    formRef,
  });

  const getCurrentStepSchema = useCallback(() => {
    const currentStep = getCurrentStep();
    if (!currentStep || !formSchema) return null;
    return {
      title: formSchema.properties[currentStep].title || currentStep,
      type: 'object',
      properties: {
        [currentStep]: formSchema.properties[currentStep],
      },
    };
  }, [formSchema, getCurrentStep]);

  const getCurrentStepData = useCallback(() => {
    const currentStep = getCurrentStep();
    if (!currentStep || !formData) return {};
    return {
      [currentStep]: formData[currentStep],
    };
  }, [formData, getCurrentStep]);

  const handleSubmit = async ({ formData: stepData }) => {
    try {
      setSubmitting(true);
      setError(null);

      const currentStep = getCurrentStep();
      const updatedFormData = {
        ...formData,
        [currentStep]: stepData[currentStep],
      };

      console.log('Updating complete form data:', updatedFormData);
      setFormData(updatedFormData);

      const isLastStep = activeStep === Object.keys(formSchema.properties || {}).length - 1;

      if (isLastStep) {
        if (isEditMode && submissionId) {
          console.log('Saving complete form data:', updatedFormData);
          await documentService.updateUserForm(token, submissionId, {
            data: updatedFormData,
          });
        } else {
          await documentService.submitForm(token, documentId, {
            data: updatedFormData,
            form_id: documentId,
            form_schema: formSchema,
          });
        }

        console.log('Generating PDF with complete data:', updatedFormData);
        const pdfBlob = await documentService.generatePDF(token, documentId, updatedFormData);
        documentService.downloadPDF(pdfBlob, documentId);

        navigate('/view');
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } catch (err) {
      setError(err.message || 'Failed to submit form');
      console.error('Error in submission process:', err);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto mt-10">
        <Alert variant="destructive">
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      </div>
    );
  }

  if (!formSchema) {
    return (
      <div className="max-w-4xl mx-auto mt-10">
        <Alert>
          <AlertTitle>
            <FormattedMessage id="documentView.noFormSchema" />
          </AlertTitle>
        </Alert>
      </div>
    );
  }

  const currentStepSchema = getCurrentStepSchema();
  const currentStepData = getCurrentStepData();
  const totalSteps = Object.keys(formSchema.properties || {}).length;

  return (
    <div className="max-w-4xl mx-auto mt-10 mb-10">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{formSchema.title}</h2>
            {isEditMode && (
              <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
                <FormattedMessage id="documentView.editMode" />
              </span>
            )}
          </div>
          <div className="flex items-center space-x-2">
            {Array.from({ length: totalSteps }, (_, index) => (
              <div
                key={index}
                className={`h-2 flex-1 rounded ${index <= activeStep ? 'bg-blue-500' : 'bg-gray-200'}`}
              />
            ))}
          </div>
          <p className="text-sm text-gray-600 mt-2">
            <FormattedMessage id="documentView.stepIndicator" values={{ currentStep: activeStep + 1, totalSteps }} />
          </p>
        </div>

        {currentStepSchema && (
          <Form
            ref={formRef}
            schema={currentStepSchema}
            formData={currentStepData}
            onSubmit={handleSubmit}
            disabled={submitting}
            validator={validator}
            uiSchema={{
              "ui:submitButtonOptions": {
                "norender": true,
              },
            }}
          >
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={() => setActiveStep((prev) => Math.max(0, prev - 1))}
                disabled={activeStep === 0 || submitting}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
              >
                <FormattedMessage id="documentView.previousButton" />
              </button>
              <button
                type="submit"
                disabled={submitting}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
              >
                {submitting ? (
                  <FormattedMessage id="documentView.processing" />
                ) : activeStep === totalSteps - 1 ? (
                  isEditMode ? (
                    <FormattedMessage id="documentView.updateAndGeneratePDFButton" />
                  ) : (
                    <FormattedMessage id="documentView.submitAndGeneratePDFButton" />
                  )
                ) : (
                  <FormattedMessage id="documentView.nextButton" />
                )}
              </button>
            </div>
          </Form>
        )}
      </div>
    </div>

  );
}

export default DocumentView;
