import React, { createContext, useState, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import Romanian from '../locales/ro.json';
import English from '../locales/en.json';

const LocaleContext = createContext();

const messages = {
  'ro': Romanian,
  'en': English
};

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState('ro');

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="ro">
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};