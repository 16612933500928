import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { FormattedMessage } from 'react-intl';

function DashboardPage() {
  const { user } = useAuth();

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-6">
        <FormattedMessage id="dashboard.title" defaultMessage="Dashboard" />
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          <FormattedMessage 
            id="dashboard.welcome" 
            defaultMessage="Welcome, {email}!"
            values={{ email: user?.email }}
          />
        </h2>
        <p className="text-gray-600">
          <FormattedMessage 
            id="dashboard.description" 
            defaultMessage="This is your dashboard. Here you'll be able to manage your forms." 
          />
        </p>
      </div>
    </div>
  );
}

export default DashboardPage;