import React from 'react';
import DataView from './DataView';
import DocsView from './DocsView';
import { FormattedMessage } from 'react-intl';

export default function ViewSwitch() {
  const [activeView, setActiveView] = React.useState('data');

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <div className="inline-flex rounded-lg border border-gray-200 bg-white p-1 shadow-sm">
          <button
            onClick={() => setActiveView('data')}
            className={`inline-block rounded-md px-4 py-2 text-sm font-medium transition-all ${
              activeView === 'data'
                ? 'bg-blue-500 text-white'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            <FormattedMessage id="viewSwitch.data" defaultMessage="Data" />
          </button>
          <button
            onClick={() => setActiveView('docs')}
            className={`inline-block rounded-md px-4 py-2 text-sm font-medium transition-all ${
              activeView === 'docs'
                ? 'bg-blue-500 text-white'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            <FormattedMessage id="viewSwitch.docs" defaultMessage="Docs" />
          </button>
        </div>
      </div>

      {activeView === 'data' ? <DataView /> : <DocsView />}
    </div>
  );
}