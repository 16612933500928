import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getApiUrl } from '../config/config';
import { FormattedMessage } from 'react-intl';

const StepnameSidebar = ({ stepnames, activeStep, onStepSelect }) => (
  <div className="w-48 bg-white shadow-md rounded-lg overflow-hidden">
    {stepnames.map((stepname) => (
      <button
        key={stepname}
        onClick={() => onStepSelect(stepname)}
        className={`w-full text-left px-4 py-3 hover:bg-gray-50 transition-colors ${
          activeStep === stepname ? 'bg-blue-50 border-l-4 border-blue-500' : ''
        }`}
      >
        <FormattedMessage 
          id={`steps.${stepname.toLowerCase()}`} 
          defaultMessage={stepname} 
        />
      </button>
    ))}
  </div>
);

const EntryList = ({ entries }) => {
  if (!entries?.length) {
    return (
      <div className="p-4 text-gray-500">
        <FormattedMessage 
          id="data.noEntriesFound" 
          defaultMessage="No entries found for this step." 
        />
      </div>
    );
  }

  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return <FormattedMessage id="common.na" defaultMessage="N/A" />;
    }
    
    if (typeof value === 'boolean') {
      return value ? 
        <FormattedMessage id="common.yes" defaultMessage="Yes" /> : 
        <FormattedMessage id="common.no" defaultMessage="No" />;
    }
    
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return <FormattedMessage id="common.noItems" defaultMessage="No items" />;
      }
      return (
        <div className="space-y-2">
          {value.map((item, i) => (
            <div key={i} className="ml-4 p-2 bg-gray-50 rounded">
              {typeof item === 'object' ? 
                Object.entries(item).map(([k, v]) => (
                  <div key={k} className="ml-2">
                    <span className="font-medium">{k}: </span>
                    {renderValue(v)}
                  </div>
                )) : 
                renderValue(item)
              }
            </div>
          ))}
        </div>
      );
    }
    
    if (typeof value === 'object') {
      if ('default' in value) {
        return renderValue(value.default);
      }
      
      if (Object.keys(value).length === 0) {
        return <FormattedMessage id="data.noData" defaultMessage="No data" />;
      }
      
      return (
        <div className="space-y-1">
          {Object.entries(value)
            .filter(([key, val]) => val !== undefined)
            .map(([key, val]) => (
              <div key={key}>
                <span className="font-medium">
                  <FormattedMessage 
                    id={`data.field.${key.toLowerCase()}`}
                    defaultMessage={key}
                  />: </span>
                {renderValue(val)}
              </div>
            ))}
        </div>
      );
    }
    
    return String(value);
  };

  return (
    <div className="space-y-4">
      {entries.map((entry, index) => (
        <div key={entry.submission_id || index} className="bg-white p-6 rounded-lg shadow">
          <div className="font-medium text-xl mb-4 text-blue-600">
            <FormattedMessage 
              id="data.entryNumber" 
              defaultMessage="Entry {number}"
              values={{ number: index + 1 }}
            />
          </div>
          <div className="space-y-4">
            {Object.entries(entry.data).map(([stepKey, stepData]) => (
              Object.entries(stepData).map(([field, value]) => (
                <div key={field} className="border-b pb-4 last:border-0">
                  <div className="font-medium text-gray-700 mb-2">
                    <FormattedMessage 
                      id={`data.field.${field.toLowerCase()}`}
                      defaultMessage={field}
                    />
                  </div>
                  <div className="text-gray-600">
                    {renderValue(value)}
                  </div>
                </div>
              ))
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
  export default function DataView() {
    const { token } = useAuth();
    const [stepnames, setStepnames] = useState([]);
    const [activeStep, setActiveStep] = useState(null);
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    // Wrap stepnameToNumber in useMemo
    const stepnameToNumber = useMemo(() => ({
      'Company': 'step1',
      'Client': 'step2',
      'Vehicle': 'step3',
      'Observations': 'step4',
      'Labor': 'step5',
      'Parts': 'step6'
    }), []);
  
    // Extract data for specific stepname - wrapped in useCallback
    const extractStepData = useCallback((forms, stepname) => {
      console.log('Extracting data for stepname:', stepname);
      const stepKey = stepnameToNumber[stepname];
      console.log('Looking for step key:', stepKey);
      
      return forms.map(form => {
        try {
          const formData = form.data || {};
          console.log('Form data:', formData);
  
          if (formData[stepKey]) {
            return {
              submission_id: form._id,
              form_id: form.form_id,
              data: {
                [stepKey]: formData[stepKey]
              }
            };
          }
  
          if (formData.form_schema?.properties?.[stepKey]?.properties) {
            const stepData = {};
            const properties = formData.form_schema.properties[stepKey].properties;
            
            Object.entries(properties).forEach(([key, prop]) => {
              if (prop.default !== undefined) {
                stepData[key] = prop.default;
              }
            });
  
            if (Object.keys(stepData).length > 0) {
              return {
                submission_id: form._id,
                form_id: form.form_id,
                data: {
                  [stepKey]: stepData
                }
              };
            }
          }
  
          if (formData.data?.[stepKey]) {
            return {
              submission_id: form._id,
              form_id: form.form_id,
              data: {
                [stepKey]: formData.data[stepKey]
              }
            };
          }
  
          return null;
        } catch (err) {
          console.error('Error processing form data:', err);
          return null;
        }
      }).filter(Boolean);
    }, [stepnameToNumber]);
  
    useEffect(() => {
      const fetchStepnames = async () => {
        if (!token) return;
        
        try {
          setLoading(true);
          setError(null);
          const response = await fetch(getApiUrl('/user/available_stepnames'), {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (!response.ok) {
            throw new Error('data.error.fetchStepnames');
          }
          
          const data = await response.json();
          setStepnames(data);
          if (data.length > 0) {
            setActiveStep(data[0]);
          }
        } catch (err) {
          console.error('Error fetching stepnames:', err);
          setError(err.message || 'data.error.fetchStepnames');
        } finally {
          setLoading(false);
        }
      };
  
      fetchStepnames();
    }, [token]);
  
    useEffect(() => {
      const fetchEntries = async () => {
        if (!activeStep || !token) return;
  
        try {
          setLoading(true);
          setError(null);
          
          const response = await fetch(getApiUrl('/user/completed_forms'), {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (!response.ok) {
            throw new Error('data.error.fetchEntries');
          }
          
          const forms = await response.json();
          const stepEntries = extractStepData(forms, activeStep);
          setEntries(stepEntries);
        } catch (err) {
          console.error('Error fetching entries:', err);
          setError(err.message || 'data.error.fetchEntries');
        } finally {
          setLoading(false);
        }
      };
  
      if (activeStep) {
        fetchEntries();
      }
    }, [activeStep, token, extractStepData]);
  
    if (error) {
      return (
        <div className="container mx-auto p-4">
          <div className="p-6 bg-red-50 border border-red-200 rounded-lg">
            <div className="text-red-600 font-medium">
              <FormattedMessage id="common.error" defaultMessage="Error" />
            </div>
            <div className="text-red-500">
              <FormattedMessage id={error} defaultMessage="An error occurred" />
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="container mx-auto p-4">
        <div className="flex gap-6">
          <StepnameSidebar 
            stepnames={stepnames} 
            activeStep={activeStep}
            onStepSelect={setActiveStep}
          />
          <div className="flex-1">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : (
              <EntryList entries={entries} />
            )}
          </div>
        </div>
      </div>
    );
  }