import React, { useRef, useState } from 'react';
import { Globe } from 'lucide-react';
import { useClickOutside } from '../utils/useClickOutside';

export default function LanguageSelector({ locale, setLocale }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Use custom hook to handle clicks outside the dropdown
  useClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Dropdown toggle button */}
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-md hover:bg-gray-100"
        aria-label="Language selector"
        aria-expanded={isDropdownOpen}
        aria-controls="language-dropdown"
      >
        <Globe className="w-5 h-5" />
        {/* Display the current locale, with a fallback */}
        <span className="uppercase">{locale || 'en'}</span>
      </button>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div
          id="language-dropdown"
          className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50"
        >
          {/* Language options */}
          <button
            onClick={() => {
              setLocale('ro'); // Switch to Romanian
              setIsDropdownOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Română
          </button>
          <button
            onClick={() => {
              setLocale('en'); // Switch to English
              setIsDropdownOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            English
          </button>
        </div>
      )}
    </div>
  );
}
