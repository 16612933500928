import { useState, useCallback } from 'react';
import { documentService } from '../../services/documentService';

export function useDocumentFetch(token) {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDocuments = useCallback(async () => {
    if (!token) return;
    setIsLoading(true);
    setError(null); // Reset error before fetching
    try {
      const fetchedDocs = await documentService.getFormsUser(token);
      setDocuments(fetchedDocs);
    } catch (err) {
      setError('Failed to load documents');
      console.error('Error fetching documents:', err);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  return [documents, isLoading, fetchDocuments, error];
}
