import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useLocale } from '../../contexts/LocaleContext';
import DocumentDropdown from './DocumentDropdown';
import LanguageSelector from './LanguageSelector';
import MobileMenu from './MobileMenu';
import { useDocumentFetch } from '../utils/useDocumentFetch';
import { useCustomEvents } from '../utils/useCustomEvents';
import { Menu, X } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import { FormattedMessage } from 'react-intl';

export default function Navbar() {
  const { isAuthenticated, token, logout } = useAuth();
  const { locale, setLocale } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [documents, isLoading, fetchDocuments, error] = useDocumentFetch(token);

  const isDocumentView = location.pathname.startsWith('/documents/');
  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get('mode') === 'edit';

  const { handleResetForm, handleGeneratePDF } = useCustomEvents();

  useEffect(() => {
    if (isAuthenticated) {
      fetchDocuments();
    }
  }, [isAuthenticated, fetchDocuments]);

  const handleLogout = () => {
    logout();
    navigate('/login');
    setIsMobileMenuOpen(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Branding */}
          <Link to="/" className="text-xl font-semibold text-gray-800">
            la-moldo
          </Link>

          {/* Mobile Menu Toggle */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            aria-label="Toggle menu"
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {/* Help Link */}
            <Link
              to="/help"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/help') ? 'text-gray-900 font-bold' : 'text-gray-700'
              }`}
            >
              Help
            </Link>

            {/* Language Selector */}
            <LanguageSelector locale={locale} setLocale={setLocale} />

            {isAuthenticated ? (
              <>
                {/* Data Link */}
                <Link
                  to="/view"
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                  Data
                </Link>

                {/* Document Dropdown */}
                <div className="relative">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    disabled={isLoading}
                    aria-expanded={isDropdownOpen}
                    aria-controls="document-dropdown"
                  >
                    Documents
                    {isLoading && (
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin ml-2"></div>
                    )}
                  </button>
                  {error && <div className="text-red-500 text-sm">{error}</div>}
                  <DocumentDropdown
                    documents={documents}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onSelect={(doc) => navigate(`/documents/${doc._id}`)}
                  />
                </div>

                {/* Actions (PDF/Reset Form) */}
                {isDocumentView && isEditMode && (
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => setShowSaveDialog(true)}
                      className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Generate PDF
                    </button>
                    <button
                      onClick={handleResetForm}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Reset Form
                    </button>
                  </div>
                )}

                {/* Logout */}
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                >
                  Logout
                </button>
              </>
            ) : (
              <div className="space-x-4">
                {/* Login/Sign Up */}
                <Link
                  to="/login"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                  Register
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <MobileMenu setIsMobileMenuOpen={setIsMobileMenuOpen} />
        )}

        {/* Save Dialog */}
        <AlertDialog open={showSaveDialog} onOpenChange={setShowSaveDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                <FormattedMessage id="dialog.generatePDF.title" defaultMessage="Generate PDF" />
              </AlertDialogTitle>
              <AlertDialogDescription>
                <FormattedMessage 
                  id="dialog.generatePDF.description" 
                  defaultMessage="Do you want to save your changes before generating the PDF?" 
                />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => {
                handleGeneratePDF(false);
                setShowSaveDialog(false);
              }}>
                <FormattedMessage id="dialog.generatePDF.previewOnly" defaultMessage="Preview Only" />
              </AlertDialogCancel>
              <AlertDialogAction onClick={() => {
                handleGeneratePDF(true);
                setShowSaveDialog(false);
              }}>
                <FormattedMessage id="dialog.generatePDF.saveAndGenerate" defaultMessage="Save and Generate" />
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </nav>
  );
}