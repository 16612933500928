import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Clock, 
  Search, 
  BarChart3, 
  FileCheck,
  ArrowRight,
  Wrench,
  ClipboardList,
  Shield,
  Briefcase,
  Calendar,
  Calculator,
  Users,
  Key,
  Package,
  Heart,
  GraduationCap,
  Palette,
  ChevronRight,
  ChevronDown
} from 'lucide-react';
import { FormattedMessage } from 'react-intl';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center justify-center w-12 h-12 mb-4 bg-indigo-100 rounded-lg">
      <Icon className="w-6 h-6 text-indigo-600" />
    </div>
    <h3 className="text-xl font-semibold mb-2"><FormattedMessage id={title} /></h3>
    <p className="text-gray-600"><FormattedMessage id={description} /></p>
  </div>
);

const ProcessStep = ({ number, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-indigo-600 text-white flex items-center justify-center font-semibold">
      {number}
    </div>
    <div>
      <h4 className="font-semibold text-lg mb-1"><FormattedMessage id={title} /></h4>
      <p className="text-gray-600"><FormattedMessage id={description} /></p>
    </div>
  </div>
);

const IndustrySolution = ({ icon: Icon, title, examples, isOpen, onToggle }) => (
  <div className="border rounded-lg bg-white overflow-hidden">
    <button 
      onClick={onToggle}
      className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50"
    >
      <div className="flex items-center space-x-3">
        <Icon className="w-6 h-6 text-indigo-600" />
        <h3 className="font-semibold text-lg"><FormattedMessage id={title} /></h3>
      </div>
      {isOpen ? <ChevronDown className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
    </button>
    {isOpen && (
      <div className="px-6 py-4 bg-gray-50 border-t">
        <ul className="space-y-2">
          {examples.map((example, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">&bull;</span>
              <span className="text-gray-600"><FormattedMessage id={example} /></span>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

const LandingPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [openSection, setOpenSection] = useState(null);

  const features = [
    {
      icon: Clock,
      title: "features.saveHours.title",
      description: "features.saveHours.description" 
    },
    {
      icon: Search,
      title: "features.neverLose.title",
      description: "features.neverLose.description"
    },
    {
      icon: BarChart3,
      title: "features.turnData.title", 
      description: "features.turnData.description"
    },
    {
      icon: FileCheck,
      title: "features.ensureConsistency.title",
      description: "features.ensureConsistency.description"
    }
  ];

  const industrySolutions = [
    {
      icon: Wrench,
      title: "industry.repair.title",
      examples: [
        "industry.repair.example1",
        "industry.repair.example2", 
        "industry.repair.example3"
      ]
    },
    {
      icon: ClipboardList,
      title: "industry.maintenance.title",
      examples: [
        "industry.maintenance.example1",
        "industry.maintenance.example2",
        "industry.maintenance.example3" 
      ]
    },
    {
      icon: Calculator,
      title: "industry.financial.title",
      examples: [
        "industry.financial.example1",
        "industry.financial.example2",
        "industry.financial.example3"
      ]
    },
    {
      icon: Briefcase,
      title: "industry.job.title",
      examples: [
        "industry.job.example1",
        "industry.job.example2",
        "industry.job.example3"
      ]  
    },
    {
      icon: Shield,
      title: "industry.safety.title",
      examples: [
        "industry.safety.example1",
        "industry.safety.example2",
        "industry.safety.example3"
      ]
    },
    {
      icon: Users, 
      title: "industry.customerRelations.title",
      examples: [
        "industry.customerRelations.example1",
        "industry.customerRelations.example2",
        "industry.customerRelations.example3"  
      ]
    },
   {
     icon: Package,
     title: "industry.inventory.title",
     examples: [
       "industry.inventory.example1",
       "industry.inventory.example2",
       "industry.inventory.example3"
     ]
   },
   {
     icon: Key,
     title: "industry.rental.title",
     examples: [
       "industry.rental.example1",
       "industry.rental.example2",
       "industry.rental.example3"
     ]
   },
   {
    icon: Calendar,
    title: "industry.scheduling.title",
    examples: [
      "industry.scheduling.example1",
      "industry.scheduling.example2", 
      "industry.scheduling.example3"
    ]
  },
  {
    icon: GraduationCap,
    title: "industry.education.title", 
    examples: [
      "industry.education.example1",
      "industry.education.example2",
      "industry.education.example3"  
    ]
  },
  {
    icon: Heart,
    title: "industry.healthcare.title",
    examples: [
      "industry.healthcare.example1",
      "industry.healthcare.example2",
      "industry.healthcare.example3"
    ]  
  },
  {
    icon: Palette,
    title: "industry.creative.title",
    examples: [
      "industry.creative.example1",
      "industry.creative.example2",
      "industry.creative.example3"
    ]
  }
 ];

 return (
   <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
     {/* Hero Section */}
     <div className="container mx-auto px-4 py-16">
       <div className="text-center max-w-4xl mx-auto">
         <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
           <FormattedMessage id="landing.hero.title" />
         </h1>
         <p className="text-xl text-gray-600 mb-8">
           <FormattedMessage id="landing.hero.description" />
         </p>
         {isAuthenticated ? (
           <button
             onClick={() => navigate('/dashboard')}
             className="bg-indigo-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors"
           >
             <FormattedMessage id="landing.hero.accessDashboard" />
           </button>
         ) : (
           <div className="space-x-4">
             <button
               onClick={() => navigate('/login')}
               className="bg-indigo-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors"
             >
               <FormattedMessage id="landing.hero.getStarted" />
             </button>
             <button
               onClick={() => navigate('/contact')}
               className="bg-white text-indigo-600 px-8 py-3 rounded-lg text-lg font-semibold border-2 border-indigo-600 hover:bg-indigo-50 transition-colors"
             >
               <FormattedMessage id="landing.hero.contactUs" />
             </button>
           </div>
         )}
       </div>
     </div>

     {/* How It Works Section */}
     <div className="bg-white py-16">
       <div className="container mx-auto px-4">
         <h2 className="text-3xl font-bold text-center mb-12">
           <FormattedMessage id="landing.howItWorks.title" />
         </h2>
         <div className="max-w-3xl mx-auto space-y-8">
           <ProcessStep 
             number="1"
             title="landing.howItWorks.step1.title"
             description="landing.howItWorks.step1.description"
           />
           <ProcessStep 
             number="2"
             title="landing.howItWorks.step2.title"
             description="landing.howItWorks.step2.description"  
           />
           <ProcessStep 
             number="3"
             title="landing.howItWorks.step3.title"
             description="landing.howItWorks.step3.description"
           />
           <ProcessStep 
             number="4" 
             title="landing.howItWorks.step4.title"
             description="landing.howItWorks.step4.description"
           />
         </div>
       </div>
     </div>

     {/* Benefits Section */} 
     <div className="container mx-auto px-4 py-16">
       <h2 className="text-3xl font-bold text-center mb-12">
         <FormattedMessage id="landing.benefits.title" />
       </h2>
       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
         {features.map((feature, index) => (
           <FeatureCard key={index} {...feature} />
         ))}
       </div>
     </div>

     {/* Industry Solutions Section */}
     <div className="bg-gray-50 py-16">
       <div className="container mx-auto px-4">
         <h2 className="text-3xl font-bold text-center mb-4">
           <FormattedMessage id="landing.industry.title" />
         </h2>
         <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
           <FormattedMessage id="landing.industry.description" />
         </p>
         <div className="max-w-4xl mx-auto grid gap-4">
           {industrySolutions.map((solution, index) => (
             <IndustrySolution 
               key={index}
               {...solution}
               isOpen={openSection === index}
               onToggle={() => setOpenSection(openSection === index ? null : index)}
             />
           ))}
         </div>
       </div>
     </div>

     {/* CTA Section */}
     <div className="bg-indigo-600 py-16">
       <div className="container mx-auto px-4 text-center">
         <h2 className="text-3xl font-bold text-white mb-4">
           <FormattedMessage id="landing.cta.title" />
         </h2>
         <p className="text-indigo-100 mb-8 max-w-2xl mx-auto">
           <FormattedMessage id="landing.cta.description" />
         </p>
         <button
           onClick={() => navigate('/contact')}
           className="bg-white text-indigo-600 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-50 transition-colors inline-flex items-center"
         >
           <FormattedMessage id="landing.cta.button" /> <ArrowRight className="ml-2 w-5 h-5" />
         </button>
       </div>
     </div>

     {/* Footer */}
     <footer className="bg-gray-50 border-t border-gray-200">
       <div className="container mx-auto px-4 py-8">
         <div className="text-center text-gray-600">
           <p>&copy; 2024 la-moldo. All rights reserved.</p>
         </div>
       </div>
     </footer>
   </div>
 );
};

export default LandingPage;