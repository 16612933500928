import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useDocumentFetch } from '../utils/useDocumentFetch';

export default function MobileMenu({ setIsMobileMenuOpen }) {
  const { isAuthenticated, logout, token } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [documents, isLoading, fetchDocuments] = useDocumentFetch(token);

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchDocuments();
    }
  }, [isAuthenticated, fetchDocuments]);

  return (
    <div className="md:hidden pb-4">
      {isAuthenticated ? (
        <div className="space-y-2 px-4">
          <Link
            to="/view"
            onClick={() => setIsMobileMenuOpen(false)}
            className="block w-full text-left px-4 py-2 text-sm text-white bg-green-500 hover:bg-green-600 rounded"
          >
            Data
          </Link>

          <div>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="block w-full text-left px-4 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded"
              disabled={isLoading}
            >
              Documents
              {isLoading && (
                <span className="ml-2 inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              )}
            </button>
            {isDropdownOpen && (
              <div className="mt-2 bg-white shadow-lg w-full rounded">
                {documents.length === 0 ? (
                  <div className="px-4 py-3 text-sm text-gray-700">
                    No documents available
                  </div>
                ) : (
                  documents.map((doc) => (
                    <button
                      key={doc._id}
                      onClick={() => {
                        navigate(`/documents/${doc._id}`);
                        setIsDropdownOpen(false);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block w-full text-left px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 border-b border-gray-100"
                    >
                      {doc.title || `Deviz ${doc._id.slice(-6)}`}
                    </button>
                  ))
                )}
              </div>
            )}
          </div>

          <div className={`${isDropdownOpen ? 'mt-2' : ''}`}>
            <button
              onClick={() => {
                logout();
                setIsMobileMenuOpen(false);
              }}
              className="block w-full text-left px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 rounded"
            >
              Logout
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2 px-4">
          <Link
            to="/login"
            onClick={() => setIsMobileMenuOpen(false)}
            className="block w-full text-left px-4 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded"
          >
            Login
          </Link>
          <Link
            to="/register"
            onClick={() => setIsMobileMenuOpen(false)}
            className="block w-full text-left px-4 py-2 text-sm text-white bg-green-500 hover:bg-green-600 rounded"
          >
            Register
          </Link>
        </div>
      )}
    </div>
  );
}