// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { LocaleProvider } from './contexts/LocaleContext';
import HelpPage from './pages/HelpPage'; // Import HelpPage

// Page imports
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import DocumentView from './pages/DocumentView';
import ViewSwitch from './components/ViewSwitch';
import Navbar from './components/navbar/Navbar';

// Admin components
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './pages/admin/DashboardPage';

function PrivateRoute({ children, adminRequired = false }) {
  const { isAuthenticated, token } = useAuth();
  let isAdmin = false;

  try {
    if (token) {
      const decoded = jwtDecode(token);
      isAdmin = decoded.is_admin || false;
    }
  } catch (error) {
    console.error('Error decoding token:', error);
  }

  if (!isAuthenticated) {
    return <Navigate to={adminRequired ? "/admin/login" : "/login"} />;
  }

  if (adminRequired && !isAdmin) {
    return <Navigate to="/" />;
  }

  return children;
}

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route 
            path="/login" 
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} 
          />
          <Route 
            path="/register" 
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />} 
          />

          {/* Protected routes */}
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            } 
          />
          <Route path="/help" element={<HelpPage />} />

          <Route 
            path="/view" 
            element={
              <PrivateRoute>
                <ViewSwitch />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/documents/:documentId" 
            element={
              <PrivateRoute>
                <DocumentView />
              </PrivateRoute>
            } 
          />

          {/* Admin routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route 
            path="/admin/dashboard" 
            element={
              <PrivateRoute adminRequired={true}>
                <AdminDashboard />
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default function AppWrapper() {
  return (
    <AuthProvider>
      <LocaleProvider>
        <App />
      </LocaleProvider>
    </AuthProvider>
  );
}