import React, { useState, useEffect, useCallback } from 'react';
import { Alert, AlertTitle } from '../../components/ui/alert';
import { Upload, X, CheckSquare } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { documentService } from '../../services/documentService';
import { getApiUrl } from '../../config/config';
import { FormattedMessage } from 'react-intl';

const AdminPanel = () => {
  const { token } = useAuth();
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [usersWithAccess, setUsersWithAccess] = useState([]);
  const [usersWithoutAccess, setUsersWithoutAccess] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchForms = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(getApiUrl('/forms'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch forms');
      }

      const data = await response.json();
      setForms(data);
    } catch (err) {
      setError(<FormattedMessage id="admin.error.fetchForms" defaultMessage="Failed to fetch forms" />);
      console.error('Error fetching forms:', err);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const fetchFormUsers = useCallback(async (formId) => {
    try {
      const accessResponse = await fetch(getApiUrl(`/forms/${formId}/users`), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!accessResponse.ok) {
        throw new Error('Failed to fetch users with access');
      }

      const accessData = await accessResponse.json();
      console.log('Users with access response:', accessData);
      const usersWithAccess = accessData.users || [];

      const allUsersResponse = await fetch(getApiUrl('/users'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!allUsersResponse.ok) {
        throw new Error('Failed to fetch all users');
      }

      const allUsers = await allUsersResponse.json();
      console.log('All users response:', allUsers);

      const accessSet = new Set(usersWithAccess);
      
      const withAccess = allUsers.filter(user => 
        accessSet.has(user.id) || accessSet.has(user.email)
      );
      setUsersWithAccess(withAccess);
      
      const withoutAccess = allUsers.filter(user => 
        !accessSet.has(user.id) && !accessSet.has(user.email)
      );
      setUsersWithoutAccess(withoutAccess);

    } catch (err) {
      setError(<FormattedMessage id="admin.error.fetchUsers" defaultMessage="Failed to fetch users" />);
      console.error('Error fetching users:', err);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchForms();
    }
  }, [token, fetchForms]);

  useEffect(() => {
    if (selectedForm && token) {
      fetchFormUsers(selectedForm._id);
    }
  }, [selectedForm, token, fetchFormUsers]);

  const handleDeleteForm = async (formId) => {
    try {
      await documentService.deleteForm(token, formId);
      await fetchForms();
      setSelectedForm(null);
    } catch (err) {
      setError(<FormattedMessage id="admin.error.deleteForm" defaultMessage="Failed to delete form" />);
      console.error('Error deleting form:', err);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || !token) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const jsonContent = JSON.parse(e.target.result);
          const response = await documentService.addForm(token, jsonContent);

          if (!response.id) {
            throw new Error('Failed to upload form');
          }

          await fetchForms();
        } catch (err) {
          setError(<FormattedMessage id="admin.error.uploadForm" defaultMessage="Error parsing or uploading form" />);
          console.error('Error uploading form:', err);
        }
      };

      reader.readAsText(file);
    } catch (err) {
      setError(<FormattedMessage id="admin.error.readFile" defaultMessage="Error reading file" />);
      console.error('Error reading file:', err);
    }
  };

  const handleAccessChange = async (user, grant = true) => {
    if (!selectedForm?._id || !user?.id) return;

    try {
      const endpoint = grant ? 'allocate' : 'remove_access';
      
      const response = await fetch(getApiUrl(`/forms/${selectedForm._id}/${endpoint}`), {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: user.id })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `Failed to ${grant ? 'grant' : 'remove'} access`);
      }

      await fetchFormUsers(selectedForm._id);
    } catch (err) {
      setError(
        <FormattedMessage 
          id="admin.error.accessChange" 
          defaultMessage="Failed to {action} access"
          values={{ action: grant ? 'grant' : 'remove' }}
        />
      );
      console.error('Error updating access:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {error && (
        <Alert variant="destructive" className="mb-6" onClose={() => setError(null)}>
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      
      <div className="flex gap-6">
        {/* Forms List */}
        <div className="w-1/3 bg-white rounded-lg shadow-md p-4">
          <div className="mb-4">
            <label className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg cursor-pointer hover:bg-blue-600">
              <Upload className="w-5 h-5 mr-2" />
              <FormattedMessage id="admin.uploadForm" defaultMessage="Upload Form" />
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>
          </div>

          <div className="space-y-2">
            {loading ? (
              <div className="text-center py-4">
                <FormattedMessage id="admin.loading" defaultMessage="Loading..." />
              </div>
            ) : (
              forms.map(form => (
                <div
                  key={form._id}
                  className={`p-3 rounded-lg cursor-pointer flex justify-between items-center ${
                    selectedForm?._id === form._id
                      ? 'bg-blue-50 border border-blue-500'
                      : 'hover:bg-gray-50'
                  }`}
                  onClick={() => setSelectedForm(form)}
                >
                  <span className="flex-1">
                    {form.title || form.form_schema?.title || 
                      <FormattedMessage id="admin.untitledForm" defaultMessage="Untitled Form" />
                    }
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteForm(form._id);
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Users Management */}
        {selectedForm && (
          <div className="flex-1 space-y-6">
            {/* Users with Access */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <h3 className="text-lg font-medium mb-4">
                <FormattedMessage id="admin.usersWithAccess" defaultMessage="Users with Access" />
              </h3>
              <div className="space-y-2">
                {usersWithAccess.map(user => (
                  <div
                    key={user.id}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
                  >
                    <span>{user.email}</span>
                    <button
                      onClick={() => handleAccessChange(user, false)}
                      className="text-red-500 hover:text-red-700"
                      title={<FormattedMessage id="admin.removeAccess" defaultMessage="Remove access" />}
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ))}
                {usersWithAccess.length === 0 && (
                  <div className="text-gray-500 text-center py-2">
                    <FormattedMessage id="admin.noUsersAccess" defaultMessage="No users have access" />
                  </div>
                )}
              </div>
            </div>

            {/* Users without Access */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <h3 className="text-lg font-medium mb-4">
                <FormattedMessage id="admin.usersWithoutAccess" defaultMessage="Users without Access" />
              </h3>
              <div className="space-y-2">
                {usersWithoutAccess.map(user => (
                  <div
                    key={user.id}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-lg"
                  >
                    <span>{user.email}</span>
                    <button
                      onClick={() => handleAccessChange(user, true)}
                      className="text-green-500 hover:text-green-700"
                      title={<FormattedMessage id="admin.grantAccess" defaultMessage="Grant access" />}
                    >
                      <CheckSquare className="w-5 h-5" />
                    </button>
                  </div>
                ))}
                {usersWithoutAccess.length === 0 && (
                  <div className="text-gray-500 text-center py-2">
                    <FormattedMessage id="admin.noUsersAvailable" defaultMessage="No users available" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;