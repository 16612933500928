export function useCustomEvents() {
    const handleResetForm = () => {
      const resetEvent = new CustomEvent('resetForm');
      window.dispatchEvent(resetEvent);
    };
  
    const handleGeneratePDF = (saveData) => {
      const pdfEvent = new CustomEvent('generatePDF', {
        detail: { saveData },
      });
      window.dispatchEvent(pdfEvent);
    };
  
    return { handleResetForm, handleGeneratePDF };
  }
  