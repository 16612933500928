import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { FileText, Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '../components/ui/alert';
import { getApiUrl } from '../config/config';
import { FormattedMessage, FormattedDate } from 'react-intl';

const formatDate = (date) => {
  if (!date) return '';
  return <FormattedDate 
    value={new Date(date)}
    year="numeric"
    month="short"
    day="numeric"
  />;
};

// Recursive component to render nested objects
const ObjectRenderer = ({ value }) => {
  if (value === null || value === undefined) return <FormattedMessage id="common.na" defaultMessage="N/A" />;
  if (typeof value === 'boolean') return value ? 
    <FormattedMessage id="common.yes" defaultMessage="Yes" /> : 
    <FormattedMessage id="common.no" defaultMessage="No" />;
  if (typeof value !== 'object') return <span>{String(value)}</span>;
  
  if (Array.isArray(value)) {
    return (
      <div className="ml-4">
        {value.length === 0 ? (
          <FormattedMessage id="common.noItems" defaultMessage="No items" />
        ) : (
          value.map((item, index) => (
            <div key={index} className="mt-1">
              <ObjectRenderer value={item} />
            </div>
          ))
        )}
      </div>
    );
  }

  if ('default' in value) {
    return <ObjectRenderer value={value.default} />;
  }

  return (
    <div className="ml-4">
      {Object.entries(value).map(([key, val]) => (
        <div key={key} className="mt-1">
          <span className="font-medium">{key}: </span>
          <ObjectRenderer value={val} />
        </div>
      ))}
    </div>
  );
};

const DocSidebar = ({ documents, activeDoc, onDocSelect, onGeneratePDF, onEdit }) => (
  <div className="w-64 bg-white shadow-md rounded-lg overflow-hidden">
    {documents.map((doc) => (
      <div
        key={doc._id}
        className={`border-b ${
          activeDoc?._id === doc._id ? 'bg-blue-50 border-l-4 border-blue-500' : ''
        }`}
      >
        <div 
          onClick={() => onDocSelect(doc)}
          className="p-4 cursor-pointer hover:bg-gray-50 transition-colors"
        >
          <div className="font-medium">
            <FormattedMessage 
              id="docs.documentNumber" 
              defaultMessage="Document #{number}"
              values={{ number: doc._id.slice(-6) }}
            />
          </div>
          <div className="text-sm text-gray-500">
            <FormattedMessage 
              id="docs.created" 
              defaultMessage="Created: {date}"
              values={{ date: formatDate(doc.created_at || Date.now()) }}
            />
          </div>
          {doc.form_id && (
            <div className="text-sm text-gray-500">
              <FormattedMessage 
                id="docs.formId" 
                defaultMessage="Form ID: {id}"
                values={{ id: doc.form_id }}
              />
            </div>
          )}
        </div>
        <div className="px-4 pb-3 space-y-2">
          <button
            onClick={() => onGeneratePDF(doc)}
            className="w-full flex items-center justify-center gap-2 bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded-md text-sm font-medium transition-colors"
          >
            <Download className="w-4 h-4" />
            <FormattedMessage id="docs.generatePDF" defaultMessage="Generate PDF" />
          </button>
          <button
            onClick={() => onEdit(doc)}
            className="w-full flex items-center justify-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium transition-colors"
          >
            <FileText className="w-4 h-4" />
            <FormattedMessage id="docs.editDocument" defaultMessage="Edit Document" />
          </button>
        </div>
      </div>
    ))}
  </div>
);

const DocDetails = ({ document }) => {
  if (!document) {
    return (
      <div className="p-4 text-gray-500">
        <FormattedMessage id="docs.selectDocument" defaultMessage="Select a document to view details" />
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">
        <FormattedMessage 
          id="docs.documentNumber" 
          defaultMessage="Document #{number}"
          values={{ number: document._id.slice(-6) }}
        />
      </h2>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-700 mb-2">
            <FormattedMessage id="docs.formInformation" defaultMessage="Form Information" />
          </h3>
          <div className="text-sm text-gray-600">
            <div>
              <FormattedMessage 
                id="docs.formId" 
                defaultMessage="Form ID: {id}"
                values={{ id: document.form_id }}
              />
            </div>
            <div>
              <FormattedMessage 
                id="docs.created" 
                defaultMessage="Created: {date}"
                values={{ date: formatDate(document.created_at) }}
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-700 mb-2">
            <FormattedMessage id="docs.formData" defaultMessage="Form Data" />
          </h3>
          <div className="bg-gray-50 p-4 rounded-lg">
            <ObjectRenderer value={document.data} />
          </div>
        </div>

        {document.metadata && (
          <div>
            <h3 className="text-lg font-medium text-gray-700 mb-2">
              <FormattedMessage id="docs.metadata" defaultMessage="Metadata" />
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <ObjectRenderer value={document.metadata} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function DocsView() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [activeDoc, setActiveDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(getApiUrl('/user/completed_forms'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch documents');
        }

        const data = await response.json();
        setDocuments(data);
        if (data.length > 0) {
          setActiveDoc(data[0]);
        }
      } catch (err) {
        setError('docs.error.fetch');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [token]);

  const handleGeneratePDF = async (docData) => {
    try {
      setGeneratingPDF(true);
      setError(null);

      const response = await fetch(getApiUrl('/user/generate-pdf/'), {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          form_id: docData.form_id,
          user_id: token,
          form_data: docData.data
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const pdfBlob = await response.blob();
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      
      const downloadLink = window.document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = `document_${docData._id}.pdf`;
      window.document.body.appendChild(downloadLink);
      downloadLink.click();
      
      window.document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(pdfUrl);
    } catch (err) {
      setError('docs.error.generatePDF');
      console.error('Error generating PDF:', err);
    } finally {
      setGeneratingPDF(false);
    }
  };

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <Alert variant="destructive">
          <AlertTitle>
            <FormattedMessage id={error} defaultMessage="An error occurred" />
          </AlertTitle>
        </Alert>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  const handleEdit = (doc) => {
    sessionStorage.setItem('editFormData', JSON.stringify(doc.data));
    navigate(`/documents/${doc.form_id}?mode=edit&submissionId=${doc._id}`);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex gap-6">
        <DocSidebar 
          documents={documents} 
          activeDoc={activeDoc}
          onDocSelect={setActiveDoc}
          onGeneratePDF={handleGeneratePDF}
          onEdit={handleEdit}
        />
        <div className="flex-1">
          <DocDetails document={activeDoc} />
        </div>
      </div>
      {generatingPDF && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <div className="flex items-center space-x-3">
              <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              <span className="text-lg">
                <FormattedMessage id="docs.generatingPDF" defaultMessage="Generating PDF..." />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}