// src/pages/HelpPage.js
import React from 'react';
import { Book, FileText, Users, Settings } from 'lucide-react';
import { FormattedMessage } from 'react-intl';

const HelpSection = ({ titleId, icon: Icon, children }) => (
  <div className="mb-8">
    <div className="flex items-center mb-4">
      <Icon className="w-6 h-6 mr-2 text-blue-500" />
      <h2 className="text-xl font-semibold">
        <FormattedMessage id={titleId} />
      </h2>
    </div>
    <div className="pl-8">
      {children}
    </div>
  </div>
);

const HelpPage = () => {
  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-8">
        <FormattedMessage id="helpPage.title" />
      </h1>

      <HelpSection titleId="helpPage.gettingStarted.title" icon={Book}>
        <div className="space-y-4">
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.gettingStarted.accountSetup.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.gettingStarted.accountSetup.description" />
            </p>
          </div>
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.gettingStarted.navigation.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.gettingStarted.navigation.description" />
            </p>
          </div>
        </div>
      </HelpSection>

      <HelpSection titleId="helpPage.workingWithDocuments.title" icon={FileText}>
        <div className="space-y-4">
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.workingWithDocuments.creatingDocuments.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.workingWithDocuments.creatingDocuments.description" />
            </p>
          </div>
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.workingWithDocuments.savingAndSubmitting.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.workingWithDocuments.savingAndSubmitting.description" />
            </p>
          </div>
        </div>
      </HelpSection>

      <HelpSection titleId="helpPage.dataView.title" icon={Users}>
        <div className="space-y-4">
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.dataView.viewingData.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.dataView.viewingData.description" />
            </p>
          </div>
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.dataView.exportingData.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.dataView.exportingData.description" />
            </p>
          </div>
        </div>
      </HelpSection>

      <HelpSection titleId="helpPage.additionalSupport.title" icon={Settings}>
        <div className="space-y-4">
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.additionalSupport.technicalSupport.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.additionalSupport.technicalSupport.description" />
            </p>
          </div>
          <div>
            <h3 className="font-medium mb-2">
              <FormattedMessage id="helpPage.additionalSupport.featureRequests.title" />
            </h3>
            <p className="text-gray-600">
              <FormattedMessage id="helpPage.additionalSupport.featureRequests.description" />
            </p>
          </div>
        </div>
      </HelpSection>
    </div>
  );
};

export default HelpPage;