const environments = {
    development: {
      API_URL: 'http://localhost:8000',
    },
    production: {
      API_URL: 'https://test.la-moldo.ro',
    },
  };
  
  // Read environment from REACT_APP_ENV or default to development
  const currentEnv = process.env.REACT_APP_ENV || 'development';
  
  // Add this console log for testing
  console.log('Current Environment:', currentEnv);
  console.log('API URL:', environments[currentEnv].API_URL);
  
  export const config = environments[currentEnv];
  
  export const getApiUrl = (endpoint) => {
    return `${config.API_URL}${endpoint}`;
  };