// src/pages/admin/DashboardPage.js
import React from 'react';
import AdminPanel from '../../components/admin/AdminPanel';

const DashboardPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <AdminPanel />
    </div>
  );
};

export default DashboardPage;